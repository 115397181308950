import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeatureObjectTypes from "../components/feature-object-types"
import DeploymentOptions from "../components/deployment-options"
import Testimonials from "../components/testimonials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import ActionForm from "../components/action-form"
import SuccessStoryCard from "../components/success-story-card"
import Customers from "../components/customers"

const EsriArcGISCelanturIntegration = ({ data }) => (
  <Layout>
    <SEO
      title="Integrate Celantur Image Anonymization with Esri ArcGIS Online - Enhance GIS Privacy"
      description="Ensure compliance with data privacy laws. Seamlessly integrate Celantur's advanced image anonymization technology with your Esri ArcGIS projects"
    />

    <section className="pt-4 pt-md-11 pb-10 pb-sm-14">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 cold-md-5 col-lg-6 order-md-2">
            <Img fluid={data.esriStartupImage.childImageSharp.fluid} />
            <Img fluid={data.systemReadySpecialtyImage.childImageSharp.fluid} />
          </div>
          <div className="col-12 cold-md-7 col-lg-6 order-md-1">
            <h1 className="display-5 text-center text-md-left text-primary font-weight-bold">
              Empower Your ArcGIS Projects with Privacy-First Image Anonymization
            </h1>
            <p className="lead text-center text-md-left text-muted mb-5">
              Integrate Celantur’s state-of-the-art anonymization technology directly into your Esri ArcGIS projects.
            </p>
            <div className="text-center text-md-left">
              <a
                href="https://doc.celantur.com/tutorials/image-anonymization-in-esri-arcgis-online"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary lift event-start-demo"
              >
                ArcGIS Online <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
              </a>
              <a
                href="https://doc.celantur.com/tutorials/image-anonymization-in-esri-arcgis-pro"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary lift ml-3 event-start-demo"
              >
                ArcGIS Pro <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <Customers />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <span className="badge badge-pill badge-primary-soft mb-3">
              <span className="h6 text-uppercase">Geoprocessing Tool</span>
            </span>

            <h2 className="font-weight-bold">
              Blur Faces and License Plates
              <br />
              <span className="text-primary">in ArcGIS Pro</span>
            </h2>

            <p className="font-size-lg text-gray-700">
              Anonymize personal information in your imagery stored in ArcGIS Online layers.
            </p>

            <a
              href="https://doc.celantur.com/tutorials/image-anonymization-in-esri-arcgis-pro"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary-soft btn-sm event-start-demo"
            >
              Download &amp; Setup <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </div>

          <div class="list-group col-12 col-md-6 shadow-lift p-0">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/BXIjK5aku0U?si=_svPOY6bgOWCz0Rt"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <DeploymentOptions />
      </div>
    </section>

    <section className="my-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Success Stories</h2>
            <p className="font-size-lg text-gray-700">
              Read how industry-leading companies use Celantur Container to solve their privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>NavVis</mark> provides image anonymization on a global scale
              </>
            }
            link="/success-story-navvis/"
            image={data.navvisImage.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>greehill</mark> provides Green Asset Management without privacy concerns
              </>
            }
            link="/success-story-greehill/"
            image={data.greehillPointcloud.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <h2 className="font-weight-bold text-center">FAQ</h2>
        <details>
          <summary>Do you offer solutions for governmental and federal customers?</summary>
          <p>
            Yes, our solutions are successfully used by governmental and federal institutions e.g. in the EU and USA.
            Celantur Container can be deployed in any private or public cloud/on-premise environment and requires no
            active internet connection.
          </p>
        </details>
      </div>
    </section>

    <section className="py-8">
      <ActionForm source="esri-landing-page" />
    </section>

    <Testimonials navvis allTerraDS riegl />
  </Layout>
)

export default EsriArcGISCelanturIntegration

export const logo = graphql`
  fragment imageProductPage on File {
    childImageSharp {
      fluid(maxWidth: 640) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    greehillPointcloud: file(relativePath: { eq: "greehill-pointcloud.png" }) {
      ...imageProductPage
    }
    navvisImage: file(relativePath: { eq: "navvis-sample-1.jpg" }) {
      ...imageProductPage
    }
    headerImage: file(relativePath: { eq: "container-product.png" }) {
      ...imageProductPage
    }
    systemReadySpecialtyImage: file(relativePath: { eq: "ArcGISSystemReady-LightBackground.png" }) {
      ...imageProductPage
    }
    esriStartupImage: file(relativePath: { eq: "esri-startup-horizontal.jpg" }) {
      ...imageProductPage
    }
  }
`
